import React, { useContext, useEffect, useState } from "react"
import "../styles/AuthPage.css"
import { ReactComponent as Logo} from "../images/FooterLogo.svg"
import { Link, useLocation, useNavigate} from "react-router-dom"
import InputTypePassword from "../../../SharedComponents/InputForPassword/mainComponents/InputTypePassword"
import InputTypeText from "../../../SharedComponents/InputForText/mainComponents/InputTypeText"
import {ACCOUNT_ROUTE, REGAUTH_ROUTE } from "../../../consts/utils"
import { Context } from "../../.."
import { checkNick, fetchSubscribes, login, registration} from "../../../http/UserApi";
import { fetchLikes, fetchUsersComics } from "../../../http/ComicsApi"
import Error from "../../../SharedComponents/Modals/Error/Error"


const Auth = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const {user} = useContext(Context)
    
    const [stateOfAuth, setStateOfAuth] = useState("Auth")
    const[errorMessage, setErrorMessage] = useState("")
    const[errorStart, setErrorStart] = useState(false)

    const [AuthForm, setAuthForm] = useState({
        email: "Почта",
        password: "Пароль"
    })

    const [RegForm, setRegForm] = useState({
        email: "Почта",
        password: "Пароль",
        repeatePassword: "Повторите пароль",
        first_name: "Имя",
        last_name: "Фамилия",
        nick_name: "Ник",
    })


    useEffect(() =>{
        if(location.pathname === REGAUTH_ROUTE){
            if(location.hash === "#Registration"){
                setStateOfAuth("Reg")
            }else if(location.hash === "#Auth"){
                setStateOfAuth("Auth")
            }
        }
    }, [location])

 
    // function check(){
    //     let formData = {
    //         nick: value
    //     }
    //     checkNick(formData).then(data => console.log(data))
    // }
   
    

    const loginFunction = async () => {

        await login(AuthForm).then(data => {
            user.setUser(data)
            user.setIsAuth(true)
            fetchUsersComics(user.user.id).then(data => user.setUserComics(data))
            fetchSubscribes(user.user.id).then(data => user.setSubscribeUsers(data === null ? [] : data.users))
            fetchLikes(user.user.id).then(data => user.setUserLikes(data === null ? [] : data.comics))
            navigate(ACCOUNT_ROUTE + "#MyAccount")
        }).catch((e) => {
            setErrorMessage(e.response.data.message)
            setErrorStart(true)
        });
        
    }

    const registrationFunction = async () => {
        
        const checkform = {
            nick: RegForm.nick_name
        }

        if(RegForm.password.length < 12){
            setErrorMessage("Пароль должен содержать не меньше 12 символов")
            setErrorStart(true)
            return;
        }

        let checkparam

        await checkNick(checkform).then(data =>  {checkparam = data.message})

        if(checkparam){
            await registration(RegForm).then(data =>  {
                user.setUser(data)
                user.setIsAuth(true)
                navigate(ACCOUNT_ROUTE + "#MyAccount")
            }).catch((e) => {
                setErrorMessage(e.response.data.message)
                setErrorStart(true)
                
            });
        }else{
            setErrorMessage("Такой никнейм уже зарегестрирован")
            setErrorStart(true)
        }

       

    }

    const AuthHandler = e => {
        if(e.key === "Enter"){
            loginFunction()
        }
    }

    const RegHandler = e => {
        if(e.key === "Enter"){
            registrationFunction()
        }
    }

    return(
        <div className="AuthPageWrapper">
            <div className={`FormAuthWrapp ${stateOfAuth === "Auth" ? "AuthOpen" : "RegOpen"}`}>
                <div className="AuthFormWrapper" style={{display: stateOfAuth === "Auth" ? "flex" : "none"}}>
                    <form>
                        <center className="NameLogIn">Вход</center>
                        <InputTypeText deffValue={AuthForm.email} placeHolder={"Почта"} name={"email"} setter={setAuthForm} getter={AuthForm} functionForKeyPress={AuthHandler}/>
                        <InputTypePassword placeHolder={"Пароль"} name={"password"} setter={setAuthForm} getter={AuthForm} functionForKeyPress={AuthHandler}/>

                        <button className="logIn" type="button" onClick={() => {loginFunction()}}>Войти</button>
                    </form>
                </div>
                <div className="AuthFormWrapper" style={{display: stateOfAuth !== "Auth" ? "flex" : "none"}}>
                    <form>
                        <center className="NameLogIn">Регистрация</center>
                        <InputTypeText deffValue={RegForm.first_name} placeHolder={"Имя"} name={"first_name"} setter={setRegForm} getter={RegForm} functionForKeyPress={RegHandler}/>
                        <InputTypeText deffValue={RegForm.last_name} placeHolder={"Фамилия"} name={"last_name"} setter={setRegForm} getter={RegForm} functionForKeyPress={RegHandler}/>
                        <InputTypeText deffValue={RegForm.nick_name} placeHolder={"Ник"} name={"nick_name"} setter={setRegForm} getter={RegForm} functionForKeyPress={RegHandler}/>
                        <InputTypeText deffValue={RegForm.email} placeHolder={"Почта"} name={"email"} setter={setRegForm} getter={RegForm} functionForKeyPress={RegHandler}/>
                        <InputTypePassword placeHolder={"Пароль"} name={"password"} setter={setRegForm} getter={RegForm} functionForKeyPress={RegHandler}/>
                        <InputTypePassword placeHolder={"Повторите пароль"} name={"repeatePassword"} setter={setRegForm} getter={RegForm} functionForKeyPress={RegHandler}/>
                        <button className="logIn" type="button" onClick={() => {registrationFunction()}}>Зарегистрироваться</button>
                    </form>
                </div>
                <div className={`BannerChanger ${stateOfAuth === "Auth" ? "BannerChangerAuth" : "BannerChangerRegistration"}`}>
                    <center className="AuthPageLogo"><Logo/></center>
                    <div className="AuthPageHeader">Добро пожаловать!</div>
                    <div className="AuthPageBody">
                        { stateOfAuth === "Auth" ?
                            "У меня еще нет аккаунта"
                            :
                            "У меня уже есть аккаунт"
                        }
                    </div>
                    <div className="AuthPageButton">
                        {stateOfAuth === "Auth" ?
                        <a href="#Auth" onClick={() => {setStateOfAuth("Reg")}}>
                            Зарегистрироваться
                        </a>
                        :
                        <a href="#Registration" onClick={() => {setStateOfAuth("Auth")}}> 
                            Войти
                        </a>
                        }
                        <Link to={user.previousRoute}>Назад</Link>
                    </div>
                </div>
                
            </div>
            <div className="AgreementInfo">
                <p>При регистрации вы принимаете условия пользовательского соглашения, условия можно прочитать <a rel="noreferrer" href={process.env.REACT_APP_AGREEMENT} target="_blank">Здесь</a></p>
            </div>
            <Error start={errorStart} onClose={() => setErrorStart(false)}>{errorMessage}</Error>
        </div>
    )
}

export default Auth